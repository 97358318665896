import React, { useState } from "react";
import {
    Button,
    Container,
    Typography,
    Card,
    CardContent,
    CardMedia,
    Paper,
    Box,
} from "@mui/material";
import Result from "./Result";
import sun from "./images/sun.png";
import apples from "./images/apples.png";  
import cat from "./images/cat.png";
import shapes from "./images/shapes.jpg";
import fam from "./images/fam.jpg";
import correctSound from './images/correct.wav'
import incorrectSound from './images/icorect.wav';
import Shape from "./shape";
import fire from "./images/fire.png"
import clothes from "./images/clothes.png"
import body from "./images/body.png"



function Quiz() {
  const [questionIndex, setQuestionIndex] = useState(0);
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [showResult, setShowResult] = useState(false);
  const [answeredQuestions, setAnsweredQuestions] = useState([]); // New state variable

  const questions = [
    {
      id: 1,
      text: "ما لون الشمس؟",
      answer: true,
      image: sun,
    },
    {
      id: 2,
      text: "كم عدد التفاحات؟",
      answer: true,
      image: apples,
    },
    {
      id: 3,
      text: "ما هو نوع الحيوان؟",
      answer: true,
      image: cat,
    },
    {
      id: 4,
      text: "وصل الأشكال الهندسية المتشابه.",
      answer: true,
      image: shapes,
      shapes: [
        { name: "triangle1" },
        { name: "circle1" },
        { name: "square1" },
        { name: "square2" },
        { name: "triangle2" },
        { name: "circle2" },
      ],
    },
    {
      id: 5,
      text: "من الأشخاص بالصورة؟",
      answer: true,
      image: fam,
    },
    {
      id: 6,
      text: "حدد في اي مكان في منطقة الجسم تلبس كل قطعة؟",
      answer: true,
      image: clothes,
    },

    {
      id: 7,
      text: "ماهي اعضاء جسمك؟",
      answer: true,
      image: body,
    },
    {
      id: 8,
      text: "كيف نطفئ الحريق؟",
      answer: true,
      image: fire,
    },
  ];
    const restartQuiz = () => {
        setQuestionIndex(0);
        setCorrectAnswers(0);
        setShowResult(false);
        setAnsweredQuestions([]);
    };

  const handleAnswer = (answer, id) => {
    let sound;
    if (answer === questions[questionIndex].answer) {
      setCorrectAnswers(correctAnswers + 1);
      sound = new Audio(correctSound);
    } else {
      sound = new Audio(incorrectSound);
    }
    sound.play();

    setAnsweredQuestions([...answeredQuestions, id]); // Add the question id to the answeredQuestions array

    if (questionIndex + 1 < questions.length) {
      setQuestionIndex(questionIndex + 1);
    } else {
      setShowResult(true);
    }
  };

  return (
    <>
              {!showResult ? (
      <Container
        style={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
          <Paper
            style={{
              maxHeight: "80vh",
              overflowY: "auto",
              width: "80%",
              padding: "20px",
              borderRadius: "20px",
              direction: "rtl",
              // Add these lines to customize the scrollbar
            }}
          >
            {questions.map((q, index) => (
              <Card
                key={q.id}
                style={{ marginBottom: "20px", border: "5px solid #000" }}
              >
                {q.shapes ? (
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "repeat(3, 1fr)",
                      gap: "10px",
                      margin: "auto",
                      width: "max-content",
                    }}
                  >
                    {q.shapes.map((shape, i) => (
                      <Shape
                        key={i}
                        name={shape.name}
                        defaultPosition={shape.defaultPosition}
                      />
                    ))}
                  </div>
                ) : (
                  <CardMedia
                    component="img"
                    height="200"
                    image={q.image}
                    alt={`Question ${index + 1}`}
                    style={{
                      objectFit: "contain",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  />
                )}
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    {q.text}
                  </Typography>

                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "green",
                      margin: "8px",
                      fontSize: "20px",
                      padding: "10px 20px",
                    }}
                    onClick={() => handleAnswer(true, q.id)}
                    disabled={answeredQuestions.includes(q.id)}
                  >
                    صح
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "red",
                      margin: "8px",
                      fontSize: "20px",
                      padding: "10px 20px",
                    }}
                    onClick={() => handleAnswer(false, q.id)}
                    disabled={answeredQuestions.includes(q.id)}
                  >
                    خطأ
                  </Button>
                </CardContent>
              </Card>
            ))}
          </Paper>
      </Container>
        ) : (
          <Result correctAnswers={correctAnswers} restartQuiz={restartQuiz} />
        )}
    </>
  );
}


export default Quiz;