import React from "react";
import { Typography, Button } from "@mui/material";
import Bg from "./images/rBg.jpg";
import Shapes from "./shape"

function Result({ correctAnswers, restartQuiz }) {
    return (
      <div
        style={{
          backgroundImage: `url(${Bg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "100vh",
          width: "100vw",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          direction: "rtl",
        }}
      >
        <Typography
          variant="h4"
          gutterBottom
          style={{ fontSize: "2em", textAlign: "center" }}
        >
          نتيجة الاختبار
        </Typography>
        {correctAnswers >= 5 ? (
          <Typography
            variant="body1"
            style={{ color: "green", fontSize: "1.5em", textAlign: "center" }}
          >
            تهانينا! لقد أجاب الطفل على الأسئلة بنجاح. نتمنى لكم دوام الصحة
            والعافية.
          </Typography>
        ) : (
          <Typography
            variant="body1"
            style={{ color: "red", fontSize: "1.5em", textAlign: "center" }}
          >
            نأسف على نتيجة الاختبار. لقد تبين احتمال إصابة طفلك. نرجو مراجعة
            الطبيب المختص والاهتمام بطفلك.
          </Typography>
        )}
        <Button
          variant="contained"
          onClick={restartQuiz}
          style={{ marginTop: "20px", fontSize: "1.5em", padding: "10px 20px" , backgroundColor:"gray" }}
        >
          إعادة الاختبار
        </Button>
        <div
          style={{ marginTop: "20px", fontSize: "1.5em", padding: "10px 20px" }}
        >
        </div>
      </div>
    );
}

export default Result;