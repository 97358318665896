import React from 'react';
import Draggable from 'react-draggable';
import triangle1 from './images/traingle1.jpg'; // replace with your image path
import triangle2 from './images/traingle2.png'; // replace with your image path
import circle1 from "./images/circle1.jpg"; // replace with your image path
import circle2 from "./images/circle2.png"; // replace with your image path
import square1 from './images/square1.jpg'; // replace with your image path
import square2 from './images/square2.png'; // replace with your image path

const Shape = ({ name, defaultPosition }) => {
    const shapeImages = {
        triangle1,
        circle1,
        square1,
        triangle2,
        circle2,
        square2
    };

    return (
        <Draggable >
            <img src={shapeImages[name]} alt={name} style={{ width: '80px', height: '80px' }} />
        </Draggable>
    );
};

export default Shape;