import React, { useState } from "react";
import { Button, Container, Typography } from "@mui/material";
import Quiz from "./Quiz";
import Bg from "./images/Bg.jpg"; // Import the image
import QBg from "./images/qBg.jpg"; // Import the image


function App() {
  const [startQuiz, setStartQuiz] = useState(false);
  const handleStartQuiz = () => {
    setStartQuiz(true);
  };

  return (
    <Container
      style={{
        backgroundImage: `url(${!startQuiz ? Bg : QBg})`,
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "100vw",

        direction: "rtl",
      }}
    >
      {!startQuiz ? (
        <div
          style={{
            padding: "20px",
            borderRadius: "10px",
            textAlign: "center",
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            style={{ fontSize: "2.5em", marginBottom: "50px" }}
          >
            اختبري صحه النمو العقلي لطفلك{" "}
          </Typography>
          <Button
            variant="contained"
            onClick={handleStartQuiz}
            style={{
              fontSize: "2em",
              padding: "15px 25px",
              backgroundColor: "gray",
            }}
          >
            بدء الاختبار
          </Button>
        </div>
      ) : (
        <Quiz />
      )}
    </Container>
  );
}

export default App;
